@import "argon-dashboard/custom/functions";
@import "argon-dashboard/custom/variables";
@import "argon-dashboard/custom/mixins";

// bootstrap (4.6.0) components

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";

.btn {
    background: #fafafa;
}

body {
    @each $color, $value in $theme-colors {
        .btn-#{$color} {
            @include button-variant($value, $value);
        }
    }

    @each $color, $value in $theme-colors {
        .btn-outline-#{$color} {
            @include button-outline-variant($value);
            background: #fafafa;
        }
    }
}

@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// Argon utilities and components

@import "argon-dashboard/custom/reboot";
@import "argon-dashboard/custom/utilities";
@import "argon-dashboard/custom/components";

// Vendor (Plugins)

@import "argon-dashboard/custom/vendors";

// Docs components

@import "argon-dashboard/docs/variables";
@import "argon-dashboard/docs/nav";
@import "argon-dashboard/docs/clipboard-js";
@import "argon-dashboard/docs/component-examples";
@import "argon-dashboard/docs/prism";
@import "argon-dashboard/docs/content";
@import "argon-dashboard/docs/sidebar";
@import "argon-dashboard/docs/footer";

// React Differences
@import "react/react-differences";


.auth-page body {
    background: $green;
}

#auth-logo {
    display: inline-block;
    width: 200px;

    a {
        display: inline-block;
    }

    img {
        width: 100%;
    }

    span {
        color: white;
    }
}

@media (max-width: 767px) {
    .mobile-hidden {
        display: none !important; /* Importante para sobrescrever outras regras de exibição */
    }
}


.react-datetime-picker {
    border: 1px solid #fafafa  ;
}


#navbar-main {
    padding: 10px 0 12px;

    .clock-area label {
        display: inline-block;
        font-size: 0.8rem;
        font-weight: normal;
        color: #333;
        margin: auto 10px;
    }

    .btn {
        padding: 0.5rem 0.6rem;
        font-size: 0.8rem;
        font-weight: normal;
        height: 40px;
        box-shadow: none;
        border: 1px solid #ccc;

        &.active {
            background: #4cbc8c;
            border: #4cbc8c;
            color: #fafafa;
        }
    }

    .input-group {
        box-shadow: none;
        border: 1px solid #ccc;

        .btn {
            border: 0;
        }

        .dropdown {
            border: 0;
            border-right: 1px solid #ccc;
        }

        & > .dropdown:last-child {
            border-right: 0;
        }
    }

    input {
        height: 35.25px;
        font-size: 0.8rem;
    }

    .container-fluid {
        padding: 0 1.5rem !important;
    }

    #main-menu {
        //width: 350px;
        li {
            //display: inline-block;
            margin-top: 2px;
        }
        li + li {
            margin-left: 5px;
        }
    }
}

.active-row {
    background: #e2fff2 !important;
}

#root {
    #dropdown-accounts {
        .dropdown-menu {
            & > div {
                padding: 0;
            }
        }

        .btn-link {
            padding: 10px 20px;
        }
    }
}

#root .btn-expand {
    display: block;
    width: 100%;
}

#root .btn-link {
    padding: 10px 0;
    background: 0;
    color: $green;
    height: auto;
    border: 0;
    box-shadow: none;
    border-radius: 0;

    &:hover {
        background: #ccc;
    }
}



.transaction-table {
    td, th, td .btn {
        font-size: 0.6rem !important;
    }

    tr {
        cursor: pointer;
    }
    tr:hover {
      background: #ccc;
    }
}

#services-charts {
    .card-header {
        border-bottom: none;
        text-align: center;
        font-size: 12px;
    }
}

.default-date-picker {
    .btn-group, .input-group {
        border: 1px solid #ccc;
        box-shadow: none;
        border-radius: 0.375rem;
        background: #fafafa;

        .btn {
            box-shadow: none;
        }
    }
}

.nav-link.active {
    color: #4cbc8c !important;
}

#nav-services {
    a {
        color: #333;
    }

    a.active {
        color: #4cbc8c !important;
        border-left: 3px solid #4cbc8c !important;
    }

    li + li a {
        border-top: 1px solid #ccc;
    }
}

#root .btn-link.active {
    color: #4cbc8c !important;
    border-left: 3px solid #4cbc8c !important;
}

.default-table {
    td, th {
        vertical-align: middle;
    }

    th {
        border-top: 0;
    }

    tbody {
        tr:hover {
            background: #e2fff2;
        }
    }
}

.btn.btn-small {
    font-size: 0.75rem;
    padding: 5px 10px;
}

.select-default .select__control {
    height: 48px;
}

.form-text-helper {
    font-size: 0.75rem;
    color: #333;
    font-weight: 300;

    p {
        font-size: 0.75rem;
        color: #333;
    }
}

.tag {
    display: inline-block;
    padding: 2px 10px;
    background: $green;
    border-radius: 3px;
    color: #fafafa;
    font-size: 10px;

    & + .tag {
        margin-left: 5px;
    }

    &.gray {
        background: #ccc;
        color: #333;
    }
}

span, td, th {
    font-weight: 300;
}

.card {
    border: 1px solid #ccc;
}

#main-container {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.table {
    thead {
        tr {
            th {
                border-top: 0;
                border-bottom: 2px solid #ccc;
            }
        }
    }

    tbody {
        tr {
            td {
                vertical-align: middle;
            }
        }
    }
}

.ant-picker-input {
    input {
        text-align: center !important;
    }
}

#select-service {
    .select__control {
        height: 48px;
    }

    font-size: 12px;
}

#sidenav-main {
    padding: 0;
    width: 200px;

    .navbar-nav {
        margin: 0;
    }

    .navbar-collapse {
        margin: 0;
        padding: 0
    }

    .nav-item {
        a {
            font-size: 13px;
            padding: 6px 20px;
        }

        .nav {
            display: none;
            margin-left: 20px;
        }

        &.active {
            .nav {
                display: block;
            }

            & > .nav-link {
                * {
                    font-weight: bold !important;
                }
            }
        }

        .nav-item {
            display: block;
            width: 100%;

            a {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }
}

.relative-input-group {
    button {
        height: 100%;
        border: 0
    }
}

#table-transactions {
    height: 70vh;
    overflow-y: scroll;

    div {
        padding: 10px;
        cursor: pointer;

        span {
            font-size: 12px;
            color: $green;
            display: block;
            height: 18px;
            overflow: hidden;
        }

        small {
            font-size: 9px;
        }
    }

    div + div {
        border-top: 1px solid #ccc;
    }
}

//.card-header {
//    //background: #ededed;
//    background: #4cbc8c;
//    color: #fff;
//    border: 0;
//
//    input {
//        border: 0;
//    }
//}

.horizontal-scroll {
    overflow-x: auto;
}

h1 {
    color: #4cbc8c
}

.navbar-vertical .navbar-nav .nav-link > i {
    min-width: 25px;
}

#list-transactions-wrapper {}

#services-charts {
    .pagination {
        text-align: right;
    }
}

#list-transactions {
    display: block;
    margin: 0;
    padding: 0;
    font-size: 12px;

    table {
        width: 100%;
    }

    .list-transactions-item-info {
        display: none
    }

    .list-transactions-item {
        display: block;
        padding: 10px 0;
        margin: 0;

        &.active {
            background: #efefef;
            .list-transactions-item-info {
                display: block;
                padding: 10px;
            }

            .list-transactions-item-header {
                font-weight: bold;
                color: $green;
            }
        }
    }

    .list-transactions-item-header {
        tr:hover {
            cursor: pointer;
        }
        tr:hover td {
            color: $green;
        }
    }

    .list-transactions-item + .list-transactions-item {
        border-top: 1px solid #ccc;
    }

    .table-attributes {
        border: 1px solid #ccc;
        border-collapse: collapse;
        background: #fff;

        th {
            font-weight: bold;
        }

        td, th {
            border: 1px solid #ccc;
            padding: 5px 10px;
        }
    }
}

.pagination {
    display: block;

    button {
        border: 1px solid $green;
        background: #fafafa;
        height: 39px;
        width: 39px;
        border-radius: 3px;

        &.active {
            background: $green;
            color: #fafafa;
        }

        & + button {
            margin-left: 5px;
        }
    }
}


.default-date-picker {
    .select__control {
        height: 48px;
        font-size: 14px;
    }

    .select__menu {
        font-size: 14px;
    }
}

.exception-block {
    .exception-block-stacktrace {
        width: 100%;
        height: 200px;
        max-height: 200px;
        min-height: 200px;
        resize: none;
        border: 1px solid #ccc;
        overflow-y: scroll;
        padding: 10px;
    }

    .exception-block-message {
        font-size: 14px;
    }
}

#alarm-form {
    /* label {
        font-size: 14px;
        display: block;
    }

    .select_small__control {
        height: 48px;
        width: 200px;
        font-size: 14px;
    }

    .select_small__menu {
        font-size: 14px;
    }

    .list-of-fields + .list-of-fields {
        margin-left: 10px;
    } */
}